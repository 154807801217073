import React from 'react';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import Routes from 'routes'
import { resources } from 'lang';
import useBodyClass from 'hooks/useBodyClass';
import { TooltipProvider } from '@radix-ui/react-tooltip';

export const Views = () => {
	const { locale, direction } = useSelector((state:any) => state.theme);
  //@ts-ignore
	 const currentAppLocale = resources[locale];
	useBodyClass(`dir-${direction}`);
	return (
		
		<ConfigProvider direction={direction} locale={currentAppLocale.antd}>
			<TooltipProvider
              disableHoverableContent
              delayDuration={500}
              skipDelayDuration={0}
            >
			<Routes />
			</TooltipProvider>
		</ConfigProvider>
	)
}

export default Views;