import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/home`,
        component: React.lazy(() => import('views/app-views/home')),
    },
    {
        key: 'product',
        path: `${APP_PREFIX_PATH}/product`,
        component: React.lazy(() => import('views/app-views/product')),
    },
    
    {
        key: 'order-list',
        path: `${APP_PREFIX_PATH}/order/list`,
        component: React.lazy(() => import('views/app-views/order')),
    },
    
    {
        key: 'order-add',
        path: `${APP_PREFIX_PATH}/order/add`,
        component: React.lazy(() => import('views/app-views/order/add-order')),
    },
    {
        key: 'order-detail',
        path: `${APP_PREFIX_PATH}/order/detail/:id`,
        component: React.lazy(() => import('views/app-views/order/detail-order')),
    },
    {
        key: 'product-definitions',
        path: `${APP_PREFIX_PATH}/product/definitions`,
        component: React.lazy(() => import('views/app-views/product/definitions')),
    },
    
    {
        key: 'product-definitions-variant-types',
        path: `${APP_PREFIX_PATH}/product/variant-types`,
        component: React.lazy(() => import('views/app-views/product/definitions/variant-types')),
    },
    {
        key: 'product-definitions-categories',
        path: `${APP_PREFIX_PATH}/product/categories`,
        component: React.lazy(() => import('views/app-views/product/definitions/categories')),
    },
    {
        key: 'plugin/store',
        path: `${APP_PREFIX_PATH}/plugin/store`,
        component: React.lazy(() => import('views/app-views/plugin/plugin-store')),
    },
    {
        key: 'appearance-menu',
        path: `${APP_PREFIX_PATH}/appearance/menu`,
        component: React.lazy(() => import('views/app-views/menu')),
    },
    
    {
        key: 'add-product',
        path: `${APP_PREFIX_PATH}/product/add`,
        component: React.lazy(() => import('views/app-views/product/add-product')),
    },
    
    {
        key: 'add-discount',
        path: `${APP_PREFIX_PATH}/campaigns/discount/add`,
        component: React.lazy(() => import('views/app-views/discount/add-discount')),
    },
    {
        key: 'list-discount',
        path: `${APP_PREFIX_PATH}/campaigns/discount/list`,
        component: React.lazy(() => import('views/app-views/discount/discount-list')),
    },
    {
        key: 'list-user',
        path: `${APP_PREFIX_PATH}/user/list`,
        component: React.lazy(() => import('views/app-views/user/user-list')),
    },
    {
        key: 'add-user',
        path: `${APP_PREFIX_PATH}/user/add`,
        component: React.lazy(() => import('views/app-views/user/add-user')),
    },
    
    {
        key: 'theme-list',
        path: `${APP_PREFIX_PATH}/theme/list`,
        component: React.lazy(() => import('views/app-views/theme/list')),
    },
    {
        key: 'theme-editor',
        path: `${APP_PREFIX_PATH}/theme/editor`,
        component: React.lazy(() => import('views/app-views/theme/editor')),
    },
    {
        key: 'localization',
        path: `${APP_PREFIX_PATH}/localization`,
        component: React.lazy(() => import('views/app-views/localization')),
    },
    {
        key: 'payment-methods',
        path: `${APP_PREFIX_PATH}/payment-methods`,
        component: React.lazy(() => import('views/app-views/payment-methods')),
    },
    {
        key: 'shipment-settings',
        path: `${APP_PREFIX_PATH}/shipment-settings`,
        component: React.lazy(() => import('views/app-views/shipment-settings')),
    },
    {
        key: 'blog-list',
        path: `${APP_PREFIX_PATH}/blog/list`,
        component: React.lazy(() => import('views/app-views/blog/post-list')),
    },
    {
        key: 'blog-add',
        path: `${APP_PREFIX_PATH}/blog/add`,
        component: React.lazy(() => import('views/app-views/blog/add-post')),
    },
    {
        key: 'blog-category',
        path: `${APP_PREFIX_PATH}/blog/category`,
        component: React.lazy(() => import('views/app-views/blog-category')),
    },
]